import React, { useState, useEffect, useRef } from 'react'
import XYAxis from '../docs/xy-axis.js'
import axios from 'axios'
import Chart from '../docs/chart'
import { Col, Container, Row } from 'react-bootstrap'
import {
    TEXT_REPORT_SELECTION,
    TEXT_DATA_CUTS_SELECTION,
    TEXT_OWNERSHIP,
    TEXT_STARTUP,
    TEXT_HEADCOUNT,
    TEXT_INDUSTRY_VERTICAL,
    TEXT_INDUSTRY_SECTOR,
    SURVEY_UUID_LIST,
    PRELIM_SURVEY_LIST,
    TEXT_ONE_DATA_CUT_SELECTION,
    MIN_SAMPLE_SIZE,
    TEXT_SELECT_PLACEHOLDER,
    TEXT_COUNTRY_SELECTION,
    COUNTRY_SELECTED_TEXT,
    TEXT_NO_AVAIL_DATA,
    YEAR_DATA_CUT_SELECTED_NAME,
    JOB_FUNCTION_DATA_CUT_SELECTED_NAME,
    SURVEY_COPY_RIGHT,
    SURVEY_YEAR_2023_PRELIM,
    SURVEY_YEAR_2023_2024_PRELIM,
    SURVEY_YEAR_2024_PRELIM,
    SURVEY_YEAR_2023,
    SURVEY_YEAR_2023_2024,
    SURVEY_YEAR_2024,
} from '../constants/constants'
import {
    SECTOR_GROUPS,
    VERTICALS,
    OWNERSHIP_GROUPS,
    HEADCOUNT_OPTIONS,
    STARTUP_OPTIONS,
    YEARS,
    JOB_FUNCTIONS,
    HEADCOUNT_OPTIONS_2024_2025,
} from '../constants/datacutConstants'
import {
    PRACTICE_REPORT_OPTIONS,
    STI_TYPE,
    LTI_TYPE,
    STI_PERCENT,
    STI_TYPE_COLUMN_NAME,
    LTI_TYPE_COLUMN_NAME,
    STI_BUDGETS_COLUMN_NAME,
} from '../constants/practiceReportOptionConstants'
import {
    useQueryState,
    getTotalResponse,
    ReactSelect,
    IsValidDataCutSelection,
    getDataCutSelectionTextTitle,
    getDataCutSelectionTextName,
    tableFooter,
} from '../helpers/helpers'
import { REACT_APP_SERVER_ADDRESS } from '../config'
import PageNotFound from './404Page'
import { useParams } from 'react-router-dom'
import { formatGroupLabel } from '../docs/style'
import { PRACTICE_GROUPED_COUNTRY_OPTIONS } from '../constants/practiceCountryConstants'

function Practice() {
    return (
        <div>
            <UserInput />
        </div>
    )
}

const UserInput = () => {
    const params = useParams()
    const survey = SURVEY_UUID_LIST[params.survey]
    const [countryValue, setCountryValue] = useQueryState('country')
    const [reportValue, setReportValue] = useQueryState('report')
    const [yearValue, setYearValue] = useQueryState('year')
    const [jobFunctionValue, setJobFunctionValue] = useQueryState('jobfunction')
    const [sectorValue, setSectorValue] = useQueryState('sector')
    const [verticalValue, setVerticalValue] = useQueryState('vertical')
    const [ownershipValue, setOwnershipValue] = useQueryState('ownership')
    const [headcountValue, setHeadcountValue] = useQueryState('headcount')
    const [startupValue, setStartupValue] = useQueryState('startup')

    const [headcountOptions, setHeadcountOptions] = useState(HEADCOUNT_OPTIONS)
    useEffect(() => {
        if (
            [
                SURVEY_YEAR_2023_PRELIM,
                SURVEY_YEAR_2023_2024_PRELIM,
                SURVEY_YEAR_2024_PRELIM,
                SURVEY_YEAR_2023,
                SURVEY_YEAR_2023_2024,
                SURVEY_YEAR_2024,
            ].includes(survey)
        ) {
            setHeadcountOptions(HEADCOUNT_OPTIONS)
        } else {
            setHeadcountOptions(HEADCOUNT_OPTIONS_2024_2025)
        }
    }, [survey])
    const [reportName, setReportName] = useState('')
    const [reportQuestion, setReportQuestion] = useState('')
    const [items, setItems] = useState([])
    const [allItems, setAllItems] = useState([])
    const [responseTotal, setResponseTotal] = useState()
    const [maxPercent, setMaxPercent] = useState(100)

    const [ongoingRequests, setOngoingRequests] = useState(0)

    useEffect(() => {
        clearDataChart()
        handleReportValueChange()

        if (
            (reportValue !== STI_PERCENT &&
                reportValue !== STI_TYPE &&
                reportValue !== LTI_TYPE &&
                yearValue === undefined &&
                jobFunctionValue === undefined) ||
            (reportValue === STI_PERCENT && yearValue !== undefined) ||
            ((reportValue === STI_TYPE || reportValue === LTI_TYPE) &&
                jobFunctionValue !== undefined)
        ) {
            fetchDataAndUpdateChart()
        }
    }, [
        countryValue,
        reportValue,
        sectorValue,
        verticalValue,
        ownershipValue,
        headcountValue,
        startupValue,
        yearValue,
        jobFunctionValue,
    ])

    const handleReportValueChange = () => {
        if (reportValue === STI_PERCENT) {
            if (yearValue === undefined) {
                setYearValue('AllYears')
            }
            if (jobFunctionValue !== undefined) {
                setJobFunctionValue(undefined)
            }
        } else if (reportValue === STI_TYPE || reportValue === LTI_TYPE) {
            if (jobFunctionValue === undefined) {
                setJobFunctionValue('All')
            }
            if (yearValue !== undefined) {
                setYearValue(undefined)
            }
        } else {
            if (jobFunctionValue !== undefined) {
                setJobFunctionValue(undefined)
            }
            if (yearValue !== undefined) {
                setYearValue(undefined)
            }
        }
    }

    useEffect(() => {
        updateReportQuestionName()
    }, [reportValue])

    const clearDataChart = () => {
        setResponseTotal(undefined)
    }

    useEffect(() => {
        if (items.length > 0) {
            setMaxPercent(Math.max(...items.map((item) => item.percent)))
        }
    }, [items])

    const fetchDataAndUpdateChart = async () => {
        if (reportValue) {
            if (jobFunctionValue !== 'All' && yearValue !== 'AllYears') {
                const options = {
                    method: 'GET',
                    url: `${REACT_APP_SERVER_ADDRESS}practice`,
                    params: {
                        country: countryValue,
                        report: reportValue,
                        sector: sectorValue,
                        vertical: verticalValue,
                        ownership: ownershipValue,
                        headcount: headcountValue,
                        startup: startupValue,
                        survey: survey,
                        year: yearValue,
                        jobfunction: jobFunctionValue,
                    },
                }

                try {
                    setOngoingRequests((ongoingRequests) => ongoingRequests + 1)
                    const response = await axios.request(options)
                    const respBody = response.data
                    const newData = respBody.results
                    //loop through each object in list newData and check the value of attribute genre and if it is 'Employee Stock Purchase Plan (ESSP)', replace it with 'Employee Stock Purchase Plan (ESPP)'
                    for (const [key, value] of Object.entries(newData)) {
                        if (
                            value['genre'] ===
                            'Employee Stock Purchase Plan (ESSP)'
                        ) {
                            value['genre'] =
                                'Employee Stock Purchase Plan (ESPP)'
                        }
                    }
                    setResponseTotal(getTotalResponse(newData))
                    setItems(newData)
                } catch (e) {
                } finally {
                    setOngoingRequests((ongoingRequests) => ongoingRequests - 1)
                }
            } else if (jobFunctionValue === 'All') {
                const tempList = []
                // loop through the value of json object in list JOB_FUNCTIONS and print value, exclude the value 'All'
                for (const [key, value] of Object.entries(JOB_FUNCTIONS)) {
                    if (value['value'] !== 'All') {
                        const options = {
                            method: 'GET',
                            url: `${REACT_APP_SERVER_ADDRESS}practice`,
                            params: {
                                country: countryValue,
                                report: reportValue,
                                sector: sectorValue,
                                vertical: verticalValue,
                                ownership: ownershipValue,
                                headcount: headcountValue,
                                startup: startupValue,
                                survey: survey,
                                year: yearValue,
                                jobfunction: value['value'],
                            },
                        }

                        try {
                            setOngoingRequests(
                                (ongoingRequests) => ongoingRequests + 1
                            )
                            const response = await axios.request(options)
                            const respBody = response.data
                            const newData = respBody.results
                            //loop through each object in list newData and check the value of attribute genre and if it is 'Employee Stock Purchase Plan (ESSP)', replace it with 'Employee Stock Purchase Plan (ESPP)'
                            // for (const [key, value] of Object.entries(
                            //     newData
                            // )) {
                            //     if (
                            //         value['genre'] ===
                            //         'Employee Stock Purchase Plan (ESSP)'
                            //     ) {
                            //         value['genre'] =
                            //             'Employee Stock Purchase Plan (ESPP)'
                            //     }
                            // }
                            const jobFunctionResponseTotal =
                                getTotalResponse(newData)
                            const matchingLabel = JOB_FUNCTIONS.find(
                                (jobFunction) =>
                                    jobFunction.value === value['value']
                            )
                            const resultDict = {
                                // map value['value'] to the value of json object in list JOB_FUNCTIONS and return the corresponding label
                                category: matchingLabel.tableHeader,
                                data: newData,
                                responseTotal: jobFunctionResponseTotal,
                            }
                            tempList.push(resultDict)
                        } catch (e) {
                        } finally {
                            setOngoingRequests(
                                (ongoingRequests) => ongoingRequests - 1
                            )
                        }
                    }
                }
                setAllItems(tempList)

                // check if responseTotal of each object in list tempList is less than MIN_SAMPLE_SIZE, if yes, set responseTotal to 0, else set responseTotal to 'showDataTable'
                let count = 0
                for (const [key, value] of Object.entries(tempList)) {
                    if (value['responseTotal'] < MIN_SAMPLE_SIZE) {
                        count++
                    }
                }
                if (count === tempList.length) {
                    setResponseTotal(0)
                } else {
                    setResponseTotal('showDataTable')
                }
            } else if (yearValue === 'AllYears') {
                const tempList = []
                // loop through the value of json object in list YEARS and print value, exclude the value 'All'
                for (const [key, value] of Object.entries(YEARS)) {
                    if (value['value'] !== 'AllYears') {
                        const options = {
                            method: 'GET',
                            url: `${REACT_APP_SERVER_ADDRESS}practice`,
                            params: {
                                country: countryValue,
                                report: reportValue,
                                sector: sectorValue,
                                vertical: verticalValue,
                                ownership: ownershipValue,
                                headcount: headcountValue,
                                startup: startupValue,
                                survey: survey,
                                jobfunction: jobFunctionValue,
                                year: value['value'],
                            },
                        }

                        try {
                            setOngoingRequests(
                                (ongoingRequests) => ongoingRequests + 1
                            )
                            const response = await axios.request(options)
                            const respBody = response.data
                            const newData = respBody.results
                            const yearResponseTotal = getTotalResponse(newData)
                            const matchingLabel = YEARS.find(
                                (yearValue) =>
                                    yearValue.value === value['value']
                            )
                            const resultDict = {
                                // map value['value'] to the value of json object in list JOB_FUNCTIONS and return the corresponding label
                                category: matchingLabel.tableHeader,
                                data: newData,
                                responseTotal: yearResponseTotal,
                            }
                            tempList.push(resultDict)
                        } catch (e) {
                        } finally {
                            setOngoingRequests(
                                (ongoingRequests) => ongoingRequests - 1
                            )
                        }
                    }
                }
                setAllItems(tempList)

                // check if responseTotal of each object in list tempList is less than MIN_SAMPLE_SIZE, if yes, set responseTotal to 0, else set responseTotal to 'showDataTable'
                let count = 0
                for (const [key, value] of Object.entries(tempList)) {
                    if (value['responseTotal'] < MIN_SAMPLE_SIZE) {
                        count++
                    }
                }
                if (count === tempList.length) {
                    setResponseTotal(0)
                } else {
                    setResponseTotal('showDataTable')
                }
            }
        }
    }

    const updateReportQuestionName = () => {
        for (const [key, value] of Object.entries(
            PRACTICE_REPORT_OPTIONS[survey]
        )) {
            if (reportValue === value['value']) {
                setReportName(value['title'])
                setReportQuestion(value['question'])
            }
        }
    }

    const handleCountryChange = (event) => {
        setCountryValue(event.value)
    }
    const handleReportOptionChange = (event) => {
        setReportValue(event.value)
    }

    const handleYearOptionChange = (event) => {
        if (event === null) {
            setYearValue(undefined)
        } else {
            setYearValue(event.value)
        }
    }

    const handleJobFunctionOptionChange = (event) => {
        if (event === null) {
            setJobFunctionValue(undefined)
        } else {
            setJobFunctionValue(event.value)
        }
    }

    const handleSectorChange = (event) => {
        if (event === null) {
            setSectorValue(undefined)
        } else {
            setSectorValue(event.value)
        }
    }

    const handleVerticalChange = (event) => {
        if (event === null) {
            setVerticalValue(undefined)
        } else {
            setVerticalValue(event.value)
        }
    }

    const handleOwnershipChange = (event) => {
        if (event === null) {
            setOwnershipValue(undefined)
        } else {
            setOwnershipValue(event.value)
        }
    }

    const handleHeadcountChange = (event) => {
        if (event === null) {
            setHeadcountValue(undefined)
        } else {
            setHeadcountValue(event.value)
        }
    }

    const handleStartupChange = (event) => {
        if (event === null) {
            setStartupValue(undefined)
        } else {
            setStartupValue(event.value)
        }
    }
    const ref = useRef()
    const DisplayChart = () => {
        const hdn = !(responseTotal && responseTotal >= MIN_SAMPLE_SIZE)
        const loading =
            typeof responseTotal === 'undefined' || ongoingRequests > 0
        const [divWidth, setDivWidth] = useState(window.innerWidth * 0.6)
        useEffect(() => {
            function handleResize() {
                setDivWidth(ref.current.clientWidth)
            }
            window.addEventListener('resize', handleResize)
        })

        const marginLeft = divWidth < 480 ? 200 : 280
        const width = divWidth < 480 ? 480 : divWidth > 900 ? 900 : divWidth

        let height = 35 * (items.length + 1)
        if (items.length <= 3) {
            height = 70 * (items.length + 1)
        } else if (items.length <= 6) {
            height = 50 * (items.length + 1)
        }

        const margin = {
            top: 20,
            right: 70,
            bottom: 30,
            left: marginLeft,
        }

        const transitionTime = 666

        // Initialize an empty array to hold unique genres
        const uniqueGenres = []

        // Iterate through each category's data
        allItems.forEach((item) => {
            item.data.forEach((data) => {
                // Check if the genre is not already in the uniqueGenres array
                if (!uniqueGenres.includes(data.genre)) {
                    uniqueGenres.push(data.genre)
                }
            })
        })

        return (
            <div className="Aligner">
                <div className="chart-div" hidden={hdn}>
                    <svg width={width} height={height}>
                        <XYAxis
                            data={items}
                            width={width}
                            height={height}
                            margin={margin}
                            transitionTime={transitionTime}
                            maxValue={maxPercent}
                        />
                        <Chart
                            data={items}
                            width={width}
                            height={height}
                            margin={margin}
                            transitionTime={transitionTime}
                            maxValue={maxPercent}
                        />
                    </svg>
                    <div className="x-axis-label">Percent of Companies (%)</div>
                </div>
                <div id="loader" hidden={!loading}></div>
                <div hidden={responseTotal !== 'showDataTable' || loading}>
                    {/* i want to display the data from variable allItems in a table*/}
                    <table className="table table-sm">
                        <thead className="thead-style">
                            {jobFunctionValue === 'All' &&
                            reportValue === STI_TYPE ? (
                                <th className="table-first-column-practice">
                                    {STI_TYPE_COLUMN_NAME}
                                </th>
                            ) : jobFunctionValue === 'All' &&
                              reportValue === LTI_TYPE ? (
                                <th className="table-first-column-practice">
                                    {LTI_TYPE_COLUMN_NAME}
                                </th>
                            ) : yearValue === 'AllYears' ? (
                                <th className="table-first-column-practice">
                                    {STI_BUDGETS_COLUMN_NAME}
                                </th>
                            ) : null}
                            {allItems.map((item) => (
                                <th
                                    className="table-first-row-practice"
                                    key={item.category}
                                >
                                    {item.category}
                                </th>
                            ))}
                        </thead>
                        <tbody>
                            {uniqueGenres.map((genre, genreIndex) => (
                                <tr key={genreIndex}>
                                    <td
                                        style={{
                                            width:
                                                jobFunctionValue === 'All'
                                                    ? '400px'
                                                    : yearValue === 'AllYears'
                                                    ? '200px'
                                                    : '100px',
                                        }}
                                    >
                                        {genre}
                                    </td>
                                    {allItems.map((item, itemIndex) => {
                                        const dataForGenre = item.data.find(
                                            (data) => data.genre === genre
                                        )
                                        return (
                                            <td
                                                className="table-row-practice"
                                                key={itemIndex}
                                                style={{
                                                    width:
                                                        jobFunctionValue ===
                                                            'All' ||
                                                        yearValue === 'AllYears'
                                                            ? '200px'
                                                            : '100px',
                                                }}
                                            >
                                                {item.responseTotal <
                                                MIN_SAMPLE_SIZE
                                                    ? '*'
                                                    : dataForGenre
                                                    ? `${Number(
                                                          dataForGenre.percent
                                                      ).toFixed(1)}%`
                                                    : '0.00%'}
                                            </td>
                                        )
                                    })}
                                </tr>
                            ))}
                        </tbody>
                        {tableFooter(survey, 'no', 'no', false, '')}
                    </table>
                </div>
            </div>
        )
    }
    const showResponseTotalText = () => {
        const loading =
            typeof responseTotal === 'undefined' || ongoingRequests > 0
        if (loading) {
            return ''
        } else if (responseTotal === 'showDataTable') {
            return <div></div>
        } else if (responseTotal < MIN_SAMPLE_SIZE) {
            return (
                <p className="practice-data-annotation">{TEXT_NO_AVAIL_DATA}</p>
            )
        } else {
            return (
                <div
                    className="practice-data-annotation"
                    style={{ color: 'black' }}
                >
                    <div className="practice-response-number-text">
                        Number of Responses: {responseTotal.toLocaleString()}
                    </div>
                    <br></br>
                    <div className="practice-survey-source">
                        {SURVEY_COPY_RIGHT[survey]}
                    </div>
                </div>
            )
        }
    }

    if (
        IsValidDataCutSelection(
            sectorValue,
            verticalValue,
            ownershipValue,
            headcountValue,
            startupValue,
            headcountOptions
        ) ||
        (reportValue &&
            PRACTICE_REPORT_OPTIONS[survey].find(
                (c) => c.value === reportValue
            ) === undefined) ||
        (countryValue &&
            PRACTICE_GROUPED_COUNTRY_OPTIONS[survey]
                .reduce((acc, val) => acc.concat(val.options), [])
                .find((c) => c.value === countryValue) === undefined)
    ) {
        return (
            <div>
                <PageNotFound />
            </div>
        )
    } else {
        return (
            <Container fluid>
                <div className="country-div-container">
                    <Row>
                        <Col
                            sm={12}
                            lg={3}
                            className="pane printHidden"
                            style={!reportValue ? { height: '100vh' } : {}}
                        >
                            <ReactSelect
                                label={TEXT_COUNTRY_SELECTION}
                                placeholder={TEXT_SELECT_PLACEHOLDER}
                                className="react-select form-control p-0"
                                options={
                                    PRACTICE_GROUPED_COUNTRY_OPTIONS[survey]
                                }
                                formatGroupLabel={formatGroupLabel}
                                onChange={handleCountryChange}
                                value={
                                    countryValue
                                        ? PRACTICE_GROUPED_COUNTRY_OPTIONS[
                                              survey
                                          ]
                                              .reduce(
                                                  (acc, val) =>
                                                      acc.concat(val.options),
                                                  []
                                              )
                                              .find(
                                                  (c) =>
                                                      c.value === countryValue
                                              )
                                        : TEXT_SELECT_PLACEHOLDER
                                }
                                closeMenuOnSelect={true}
                            />
                            <ReactSelect
                                label={TEXT_REPORT_SELECTION}
                                placeholder={TEXT_SELECT_PLACEHOLDER}
                                className="react-select form-control p-0"
                                options={PRACTICE_REPORT_OPTIONS[survey]}
                                onChange={handleReportOptionChange}
                                value={
                                    reportValue
                                        ? PRACTICE_REPORT_OPTIONS[survey].find(
                                              (c) => c.value === reportValue
                                          )
                                        : TEXT_SELECT_PLACEHOLDER
                                }
                                closeMenuOnSelect={true}
                                isDisabled={!countryValue}
                            />
                            {reportValue === STI_PERCENT ? (
                                <ReactSelect
                                    label="Select Year:"
                                    placeholder={TEXT_SELECT_PLACEHOLDER}
                                    className="react-select form-control p-0"
                                    options={YEARS}
                                    onChange={handleYearOptionChange}
                                    value={
                                        yearValue
                                            ? YEARS.find(
                                                  (c) => c.value === yearValue
                                              )
                                            : TEXT_SELECT_PLACEHOLDER
                                    }
                                    closeMenuOnSelect={true}
                                />
                            ) : null}

                            {reportValue === STI_TYPE ||
                            reportValue === LTI_TYPE ? (
                                <ReactSelect
                                    label="Select Job Function:"
                                    placeholder={TEXT_SELECT_PLACEHOLDER}
                                    className="react-select form-control p-0"
                                    options={JOB_FUNCTIONS}
                                    onChange={handleJobFunctionOptionChange}
                                    value={
                                        jobFunctionValue
                                            ? JOB_FUNCTIONS.find(
                                                  (c) =>
                                                      c.value ===
                                                      jobFunctionValue
                                              )
                                            : TEXT_SELECT_PLACEHOLDER
                                    }
                                    closeMenuOnSelect={true}
                                />
                            ) : null}

                            <div
                                id="datacuts-filters"
                                style={
                                    reportValue && countryValue
                                        ? { display: 'block' }
                                        : { display: 'none' }
                                }
                            >
                                <p className="report-seperator">
                                    {PRELIM_SURVEY_LIST.includes(survey)
                                        ? TEXT_ONE_DATA_CUT_SELECTION
                                        : TEXT_DATA_CUTS_SELECTION}
                                </p>
                                <ReactSelect
                                    className="react-select form-control p-0"
                                    annotation={TEXT_INDUSTRY_SECTOR}
                                    placeholder={TEXT_SELECT_PLACEHOLDER}
                                    options={SECTOR_GROUPS}
                                    formatGroupLabel={formatGroupLabel}
                                    onChange={handleSectorChange}
                                    isDisabled={
                                        PRELIM_SURVEY_LIST.includes(survey)
                                            ? verticalValue ||
                                              ownershipValue ||
                                              headcountValue ||
                                              startupValue
                                            : false
                                    }
                                    value={
                                        sectorValue
                                            ? SECTOR_GROUPS.reduce(
                                                  (acc, val) =>
                                                      acc.concat(val.options),
                                                  []
                                              ).find(
                                                  (c) => c.value === sectorValue
                                              )
                                            : TEXT_SELECT_PLACEHOLDER
                                    }
                                    isClearable={true}
                                    closeMenuOnSelect={true}
                                />
                                <ReactSelect
                                    className="react-select form-control p-0"
                                    annotation={TEXT_INDUSTRY_VERTICAL}
                                    placeholder={TEXT_SELECT_PLACEHOLDER}
                                    options={VERTICALS}
                                    onChange={handleVerticalChange}
                                    isDisabled={
                                        PRELIM_SURVEY_LIST.includes(survey)
                                            ? sectorValue ||
                                              ownershipValue ||
                                              headcountValue ||
                                              startupValue
                                            : false
                                    }
                                    value={
                                        verticalValue
                                            ? VERTICALS.find(
                                                  (c) =>
                                                      c.value === verticalValue
                                              )
                                            : TEXT_SELECT_PLACEHOLDER
                                    }
                                    isClearable={true}
                                    closeMenuOnSelect={true}
                                />
                                <ReactSelect
                                    className="react-select form-control p-0"
                                    annotation={TEXT_OWNERSHIP}
                                    placeholder={TEXT_SELECT_PLACEHOLDER}
                                    options={OWNERSHIP_GROUPS}
                                    formatGroupLabel={formatGroupLabel}
                                    onChange={handleOwnershipChange}
                                    isDisabled={
                                        PRELIM_SURVEY_LIST.includes(survey)
                                            ? sectorValue ||
                                              verticalValue ||
                                              headcountValue ||
                                              startupValue
                                            : false
                                    }
                                    value={
                                        ownershipValue
                                            ? OWNERSHIP_GROUPS.reduce(
                                                  (acc, val) =>
                                                      acc.concat(val.options),
                                                  []
                                              ).find(
                                                  (c) =>
                                                      c.value === ownershipValue
                                              )
                                            : TEXT_SELECT_PLACEHOLDER
                                    }
                                    isClearable={true}
                                    closeMenuOnSelect={true}
                                />

                                <ReactSelect
                                    className="react-select form-control p-0"
                                    annotation={TEXT_HEADCOUNT}
                                    placeholder={TEXT_SELECT_PLACEHOLDER}
                                    options={headcountOptions}
                                    onChange={handleHeadcountChange}
                                    isDisabled={
                                        PRELIM_SURVEY_LIST.includes(survey)
                                            ? sectorValue ||
                                              verticalValue ||
                                              ownershipValue ||
                                              startupValue
                                            : false
                                    }
                                    value={
                                        headcountValue
                                            ? headcountOptions.find(
                                                  (c) =>
                                                      c.value === headcountValue
                                              )
                                            : TEXT_SELECT_PLACEHOLDER
                                    }
                                    isClearable={true}
                                    closeMenuOnSelect={true}
                                />
                                <ReactSelect
                                    className="react-select form-control p-0"
                                    annotation={TEXT_STARTUP}
                                    placeholder={TEXT_SELECT_PLACEHOLDER}
                                    options={STARTUP_OPTIONS}
                                    onChange={handleStartupChange}
                                    isDisabled={
                                        PRELIM_SURVEY_LIST.includes(survey)
                                            ? sectorValue ||
                                              verticalValue ||
                                              ownershipValue ||
                                              headcountValue
                                            : false
                                    }
                                    value={
                                        startupValue
                                            ? STARTUP_OPTIONS.find(
                                                  (c) =>
                                                      c.value === startupValue
                                              )
                                            : TEXT_SELECT_PLACEHOLDER
                                    }
                                    isClearable={true}
                                    closeMenuOnSelect={true}
                                />
                            </div>
                        </Col>
                        <Col
                            sm={12}
                            lg={9}
                            className="table-area"
                            style={
                                reportValue
                                    ? { display: 'block' }
                                    : { display: 'none' }
                            }
                        >
                            <div className="printVisible">
                                <div className="logo-print-view">
                                    <img
                                        src="https://ww2.culpepper.com/images/logos/Culpepper-Logo-2024.svg"
                                        alt=""
                                        width="238.19"
                                    ></img>
                                </div>
                            </div>
                            <p className="table-title">{reportName}</p>
                            <hr style={{ width: '100%' }} />{' '}
                            <div>
                                <div className="container">
                                    <p className="practice-data-question">
                                        {reportQuestion}
                                    </p>
                                    <div className="row justify-content-start col-9 no-gutters">
                                        <div className="text-left col-md-2">
                                            <span className="datacut-type">
                                                {COUNTRY_SELECTED_TEXT}:
                                            </span>
                                        </div>
                                        <div className="text-left col-auto">
                                            {countryValue === 'Global'
                                                ? 'Global: All Countries'
                                                : countryValue}
                                        </div>
                                    </div>
                                    <div className="row justify-content-start col-9 no-gutters">
                                        <div className="text-left col-md-2">
                                            {getDataCutSelectionTextTitle(
                                                yearValue,
                                                YEAR_DATA_CUT_SELECTED_NAME
                                            )}
                                        </div>
                                        <div className="text-left col-auto">
                                            {getDataCutSelectionTextName(
                                                yearValue
                                            )}
                                        </div>
                                    </div>
                                    <div className="row justify-content-start col-9 no-gutters">
                                        <div className="text-left col-md-2">
                                            {getDataCutSelectionTextTitle(
                                                jobFunctionValue,
                                                JOB_FUNCTION_DATA_CUT_SELECTED_NAME
                                            )}
                                        </div>
                                        <div className="text-left col-auto">
                                            {getDataCutSelectionTextName(
                                                jobFunctionValue
                                            )}
                                        </div>
                                    </div>
                                    <div className="row justify-content-start col-9 no-gutters">
                                        <div className="text-left col-md-2">
                                            {getDataCutSelectionTextTitle(
                                                sectorValue,
                                                TEXT_INDUSTRY_SECTOR
                                            )}
                                        </div>
                                        <div className="text-left col-auto">
                                            {getDataCutSelectionTextName(
                                                sectorValue
                                            )}
                                        </div>
                                    </div>
                                    <div className="row justify-content-start col-9 no-gutters">
                                        <div className="text-left col-md-2">
                                            {getDataCutSelectionTextTitle(
                                                verticalValue,
                                                TEXT_INDUSTRY_VERTICAL
                                            )}
                                        </div>
                                        <div className="text-left col-auto">
                                            {getDataCutSelectionTextName(
                                                verticalValue
                                            )}
                                        </div>
                                    </div>
                                    <div className="row justify-content-start col-9 no-gutters">
                                        <div className="text-left col-md-2">
                                            {getDataCutSelectionTextTitle(
                                                ownershipValue,
                                                TEXT_OWNERSHIP
                                            )}
                                        </div>
                                        <div className="text-left col-auto">
                                            {getDataCutSelectionTextName(
                                                ownershipValue
                                            )}
                                        </div>
                                    </div>
                                    <div className="row justify-content-start col-9 no-gutters">
                                        <div className="text-left col-md-2">
                                            {getDataCutSelectionTextTitle(
                                                headcountValue,
                                                TEXT_HEADCOUNT
                                            )}
                                        </div>
                                        <div className="text-left col-auto">
                                            {getDataCutSelectionTextName(
                                                headcountValue
                                            )}
                                        </div>
                                    </div>
                                    <div className="row justify-content-start col-9 no-gutters">
                                        <div className="text-left col-md-2">
                                            {getDataCutSelectionTextTitle(
                                                startupValue,
                                                TEXT_STARTUP
                                            )}
                                        </div>
                                        <div className="text-left col-auto">
                                            {getDataCutSelectionTextName(
                                                startupValue
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="chart-container" ref={ref}>
                                    {DisplayChart()}
                                </div>

                                <br></br>
                                <div>{showResponseTotalText()}</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        )
    }
}

export default Practice
