import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import {
    SURVEY_UUID_LIST,
    SURVEY_YEAR_2023,
    SURVEY_YEAR_2023_2024,
    SURVEY_YEAR_2024,
    SURVEY_YEAR_2024_2025,
} from '../constants/constants'

function Definitions() {
    const params = useParams()
    const surveyUUID = params.survey
    const survey = SURVEY_UUID_LIST[surveyUUID]

    return (
        <div className="container">
            <h4 className="definition-title">Definitions</h4>
            <div className="col-auto">
                <Row className="definition-rows">
                    <Col>
                        <span className="definition-name">
                            * Insufficient Data.{' '}
                        </span>
                        <span>
                            Included in Regional Aggregates. Minimum of 3
                            responses required for average/mean and median (50th
                            percentile). Minimum of 5 responses required for
                            40th and 60th percentiles. Minimum of 7 responses
                            required for 25th and 75th percentiles. Minimum of
                            10 responses required for 10th and 90th percentiles.
                        </span>
                    </Col>
                </Row>
                <Row className="definition-rows">
                    <Col>
                        <span className="definition-name">
                            ~ Not Available.{' '}
                        </span>
                        <span>Data not collected or included in survey.</span>
                    </Col>
                </Row>

                <Row className="definition-rows">
                    <Col>
                        <span className="definition-name">
                            N: The number of responses.{' '}
                        </span>
                        <span>
                            The number of responses for a particular region,
                            country, and data cut. Regional aggregates may
                            include companies that submitted data for multiple
                            countries.
                        </span>
                    </Col>
                </Row>
                <Row className="definition-rows">
                    {[
                        SURVEY_YEAR_2023,
                        SURVEY_YEAR_2023_2024,
                        SURVEY_YEAR_2024,
                    ].includes(survey) ? (
                        <Col>
                            <span className="definition-name">
                                Base Salary Increases.
                            </span>
                            <span>
                                {' '}
                                For purposes of this survey, base salary
                                increases include cost of living adjustments
                                (COLA) & inflationary increases, merit
                                increases, internal pay equity adjustments,
                                external market adjustments, statutory
                                increases, and other non-promotional increases.
                            </span>
                        </Col>
                    ) : (
                        <Col>
                            <span className="definition-name">
                                Base Salary Increases.
                            </span>
                            <span>
                                {' '}
                                For purposes of this survey, base salary
                                increases include cost of living adjustments
                                (COLA) & inflationary increases,
                                merit/performance increases, internal pay equity
                                adjustments, external market/competitive
                                adjustments, promotional increases, retention
                                increases, and statutory increases mandated by
                                law or contract.
                            </span>
                        </Col>
                    )}
                </Row>
                <Row className="definition-rows">
                    <Col>
                        <span className="definition-name">
                            Base Salary Structures.
                        </span>
                        <span>
                            {' '}
                            A base salary range structure (or salary structure)
                            is a hierarchical group of jobs and base salary
                            ranges within an organization. Salary structures are
                            often expressed as pay grades or job grades that
                            reflect the value of a job in the external market
                            and/or the internal value to an organization.
                        </span>
                    </Col>
                </Row>
                {survey === SURVEY_YEAR_2024_2025 ? (
                    <Row className="definition-rows">
                        <Col>
                            <span className="definition-name">
                                Compa-Ratio.
                            </span>
                            <span>
                                {' '}
                                The Compa-Ratio is a percentage that compares an
                                employee’s salary to the midpoint of the market
                                salary range or salary range midpoint. It is
                                calculated by dividing the employee's salary by
                                the market midpoint or salary range midpoint,
                                then multiplying by 100.
                            </span>
                        </Col>
                    </Row>
                ) : null}
                <Row className="definition-rows">
                    <Col>
                        <span className="definition-name">
                            Culpepper Geographic Pay Zones.
                        </span>
                        <span>
                            {' '}
                            Culpepper Geographic Pay Zones combine data from
                            geographic locations and postal codes with similar
                            pay rates. Pay Zones span the highest-paying areas
                            (Pay Zone 1) through the lowest-paying areas (Pay
                            Zone 5).
                        </span>
                    </Col>
                </Row>
                {survey === SURVEY_YEAR_2024_2025 ? (
                    <Row className="definition-rows">
                        <Col>
                            <span className="definition-name">
                                Position-in-Range.
                            </span>
                            <span>
                                {' '}
                                The Position-in-Range percentage shows how an
                                employee's salary stands within their pay band.
                                It is calculated by subtracting the pay band
                                minimum from the employee's salary, then
                                dividing that result by the difference between
                                the pay band maximum and minimum.
                            </span>
                        </Col>
                    </Row>
                ) : null}
                <Row className="definition-rows">
                    <Col>
                        <span className="definition-name">
                            Promotional Salary Increases.
                        </span>
                        <span>
                            {' '}
                            Promotions include increases in base salaries or
                            hourly wage rates for employees promoted to a higher
                            job level or position of responsibility.
                        </span>
                    </Col>
                </Row>

                <Row className="definition-rows">
                    <Col>
                        <span className="definition-name">
                            Industry Sector.
                        </span>
                        <span>
                            {' '}
                            The primary product or service sold or provided by
                            an organization.
                        </span>
                    </Col>
                </Row>
                <Row className="definition-rows">
                    <Col>
                        <span className="definition-name">
                            Industry Vertical.
                        </span>
                        <span>
                            {' '}
                            The technology-focused vertical markets, niches, and
                            specialties represent the types of products and
                            services provided by an organization.
                        </span>
                    </Col>
                </Row>
                {[
                    SURVEY_YEAR_2023_2024,
                    SURVEY_YEAR_2024,
                    SURVEY_YEAR_2024_2025,
                ].includes(survey) ? (
                    <div>
                        <Row className="definition-rows">
                            <Col>
                                <span className="definition-name">
                                    Short-Term Cash Incentives.
                                </span>
                                <span>
                                    {' '}
                                    Short-term cash incentives (STIs) include
                                    bonuses, commissions, and other variable
                                    cash awards earned within a one-year period
                                    that are contingent on performance, results
                                    achieved, or discretion.
                                </span>
                            </Col>
                        </Row>
                        <Row className="definition-rows">
                            <Col>
                                <span className="definition-name">
                                    Long-Term Incentives.
                                </span>
                                <span>
                                    {' '}
                                    Long-term incentives (LTIs) include
                                    restricted stock units (RSUs), performance
                                    share units (PSUs), stock options, stock
                                    appreciation rights (SARs), phantom stock,
                                    employee stock ownership plans (ESOPs), and
                                    employee stock purchase plans (ESPPs),
                                    deferred profit-sharing plans, long-term
                                    cash awards, and other types of equity
                                    compensation and long-term incentive plans
                                    (LTIPs) earned for periods longer than one
                                    year.
                                </span>
                            </Col>
                        </Row>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default Definitions
